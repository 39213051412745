.navigate-tabs {
  padding-top: 1px;
  height: 4rem;
}

.navigate-tabs .tab {
  min-width: 8rem;
  padding: 1rem;
  margin-right: 1px;
  border-radius: 5px;
  background-color: var(--gray);
  color: #000;
}

.navigate-tabs .tab:hover {
  background-color: var(--light-gray) !important;
  color: var(--black);
  /* border: 1px solid var(--gray); */
}

.navigate-tabs .tab.active {
  background-color: var(--black);
  color: #fff;
}