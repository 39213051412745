#itinerary-expand {
  background: white;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  height: 100vh;
  position: absolute;
  top: 0;
  right: -65rem;
  z-index: 100;
  width: 65rem;
  padding: 1rem;
}

@keyframes itineraryExpandSlidIn {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  70% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
    transform: translateX(-65rem);
  }
}

#itinerary-expand.show {
  animation: itineraryExpandSlidIn 0.25s ease-out forwards;
}
