:root {
  --black: #4a4a4a;
  --juno: #92b2a7;
  --propke_color: #92b2a7;
  --airbnb: #f08587;

  --beige: #f4f4f1;
  --pink: #ffd2d2;
  --purple: #ff00ff;
  --grassy: #a9ff14;
  --light-green: #d9ffd2;
  --light-yellow: #fde89d;
  --light-orange: #fdd79d;
  --light-blue: #f1f4f9;
  --checkin: #c1fff2;

  --light-gray: #eee;
  --gray: #d9d9d9;
  --dark-gray: #a3a3a3;
}
