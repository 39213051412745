#vendor-form input,
select {
  min-height: 3rem;
}

.country-container input {
  min-height: unset !important;
}

#vendor-popup h5 {
  margin: 0;
}

.sub-title {
  margin: 1rem 0;
}

#vendors-table {
  position: relative;
  top: 0rem;
  margin-top: 0;
}

table .expand-cell {
  width: 4rem !important;
}

#vendors-table th {
  padding: 0 .5rem !important;
  background-color: var(--light-blue) !important;
  color: #000;
}

#vendors-table td {
  padding: 0 .5rem !important;
}

#vendors-table tr:hover {
  background-color: var(--light-gray);
}

#vendors-table tr:last-of-type {
  border-bottom: 0;
}

.title-container {
  padding: 2rem 1.2rem;
  background-color: #fefefe;
}

.highlighted-section {
  padding: 1rem 2rem;
}

.vendor-form-container {
  position: relative;
  padding: 1rem 3rem;
}

.vat-treatment {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.vat-treatment input {
  margin-right: 0.5rem;
}

.vat-treatment-container {
  height: 7rem;
  align-items: start !important;
}

.additional-id-inputs {
  width: 48% !important;
  display: inline-block !important;
}

.additional-id-inputs:first-child {
  margin-right: 2%;
}

.additional-id-inputs:last-child {
  margin-left: 2%;
}

.custom-input {
  height: 3rem;
}

.custom-expand-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../assets/Expand.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem;
  padding-right: 2rem;
}

.css-hlgwow {
  padding-bottom: 8px;
}