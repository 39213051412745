/* src/fonts.css */
@font-face {
    font-family: 'MyFont';
    src: url('/public/fonts/Poppins-Black.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MyFont';
    src: url('/public/fonts/Poppins-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'MyFont';
    src: url('/public/fonts/Poppins-ExtraBold.ttf') format('ttf');
    font-weight: 800;
    font-style: normal;
}