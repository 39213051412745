.container {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.image-selector {
    max-width: 15rem;
    max-height: 15rem;
    object-fit: cover;
    min-width: 15rem;
    min-height: 15rem;
    cursor: pointer;
}

.file-input {
    display: none;
}