.workspace-input {
    margin-top: 1rem;
    background-color: #FFF;
    height: 4rem;
    padding: .5rem;
    font-size: 2rem;
    border: 1px solid var(--light-gray);
    width: 100%;
}

.submit-button {
    background-color: var(--light-yellow);
    width: 113px;
    margin-left: 1%;
    height: 4rem;
    border: 0;
}