.invoice-issued-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

#main-box {
  /* width: 40%; */
  height: 100%;
  background-color: #fff;
  z-index: 1;
  padding: 2rem 2rem;
  border: 1px solid var(--grassy);
  border-radius: 2rem;
}

.share-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
  height: 11rem;
  flex-wrap: wrap;
  align-content: flex-end;
}

#invoice-issued-middle-header {
  height: 11rem;
  /* text-align: center; */
}

#download-invoice {
  position: absolute;
  bottom: 2rem;
}

.invoice-issued-flex-container-big {
  height: 50%;
  width: 50%;
}

.invoice-issued-flex-container {
  height: 40%;
  width: 20%;
}

.equal-flex-side {
  width: 33% !important;
}

.tax-invoice-issued {
  width: 100%;
}

.tax-invoice-issued p {
  position: absolute;
  top: -4px;
}

.download-invoice-container {
  height: 10rem;
}

.qr-code-success {
  width: 4rem;
  position: absolute;
  right: 3rem;
  bottom: 0.5rem;
}

.customer-circle-image {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
}

.issued_qr_code_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}

.invoice-issued-row * {
  width: 33%;
}

.invoice-issued-row svg {
  width: 70%;
  height: 50%;
}

.invoice-issued-row p:nth-child(even) {
  /* text-align: right;
  background-color: red; */
}