/* Add to your stylesheet, e.g., AirtableData.css */
.modal {
    position: fixed; /* Fixed positioning to keep it in view even on scroll */
    right: 0; /* Align to the far right */
    top: 245px; /* Center vertically */
    transform: translateY(-50%); /* Center vertically */
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    z-index: 1000; /* Make sure it's above other content */
}

.modal-content {
    padding: 20px;
}

.modal-header,
.modal-footer {
    padding: 10px;
    background-color: #f1f1f1;
    text-align: right;
}

.close-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}
