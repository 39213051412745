.table {
    width: 100%;
    table-layout: auto;
}

.table-header th {
    background-color: #a9ff14;
    color: #000;
    text-transform: uppercase;
    font-weight: bold;
    height: 26px;
    padding: 0px 5px 0px 5px;
    white-space: nowrap;
}

.table-cell {
    height: 26px;
    padding: 0;
    white-space: nowrap;
}

.table-row {
    &:hover {
        background-color: #f0f0f0;
    }

    height: 26px;
    padding: 0;
}

.table-row td {
    height: 26px;
    white-space: nowrap;
    padding: 0px 5px 0px 5px;
}

.table-row td button,
.table-row td span {
    padding: 0;
}

.table-row td:last-child {
    width: 100%;
}

.currency-badge {
    background-color: #006400;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 4px 8px !important;
    display: inline-block;
}