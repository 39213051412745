.i-border-r {
  border-right: 1px solid #80808026;
}

.i-no-border {
  border-color: #f3f4f6;
}

.resize-cell {
  resize: horizontal;
}

.resize-wrapper {
  resize: horizontal;
  overflow: auto;
  display: inline-block;
  max-width: 100%;
  /* Optional to restrict the maximum width */
  /* hide the scrollbar */
  scrollbar-width: none;
  min-width: 2rem;
}

.hide-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.i-border {
  border: 1px solid #80808026;
}

.context-menu {
  position: fixed;
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  width: 14rem;
}

.client-list {
  min-width: 21.4rem;
  position: absolute;
  right: -22.4rem;
  top: 0;
  background-color: #fff;
  padding: 1rem;
  z-index: 9;
  display: none;
  height: 30rem;
  overflow-y: scroll;
  scrollbar-width: none;
}

.client-form-popup {
  width: 30rem;
  right: -31rem;
}

.client-form-popup input {
  margin: 1rem 0;
  border-radius: 1rem;
  padding: 5px;
  border: 0.5px solid #eee !important;
}

.client-form-popup input[type="submit"]:hover {
  background-color: var(--grassy) !important;
}

.info-section input,
#invoice-right-section .info-section select {
  width: 100%;
  background: transparent;
  border: 0;
  outline: none;
  padding-left: 1rem;
}

.action-td {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 6px;
  padding: 8.7px 8px !important;
}

.table {
  width: 100%;
  table-layout: auto;
}

.table-header th {
  background-color: #a9ff14;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  height: 26px;
  padding: 0px 5px 0px 5px;
  white-space: nowrap;
}

.table-cell {
  height: 26px;
  padding: 0;
  white-space: nowrap;
}

.table-row {
  &:hover {
    background-color: #f0f0f0;
  }

  height: 26px;
  padding: 0;
}

.table-row td {
  height: 26px;
  white-space: nowrap;
  padding: 0px 5px 0px 5px;
}

.table-row td button,
.table-row td span {
  padding: 0;
}

.table-row td:last-child {
  width: 100%;
}

.currency-badge {
  background-color: #006400;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 4px 8px !important;
  display: inline-block;
}

.table-row td:last-child {
  width: unset !important;
}

.filter-popup {
  background-color: #fff;
  position: fixed;
  left: -65rem;
  top: 0;
  z-index: 99;
  height: 100vh;
  width: 16rem;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: auto;
  /* hide scrollbar */
  scrollbar-width: none;
}

.filter-popup .filter {
  padding: 1rem;
  border-bottom: 1px solid #ccc;
}

@keyframes popupSlidIn {
  0% {
    opacity: 0;
    transform: translateX(0);
    /* left: 10rem; */
  }

  70% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
    transform: translateX(65rem);
    /* left: 0; */
  }
}

.filter-popup.show {
  /* display: block;
  right: 0; */
  animation: popupSlidIn 0.25s ease-out forwards;
}

.description-cell {
  display: flex !important;
  align-items: center;
  gap: 1rem;
}