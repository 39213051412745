.invitation-container {
    width: 100vw;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-content: center;
}

.accept-text {
    color: #00F
}

.accept-box {
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}