.alert-message {
    position: fixed;
    padding: 1rem;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    width: 50%;
    z-index: 9999;
}

.success {
    border: 1px solid green;
    color: green;
}

.error {
    border: 1px solid red;
    color: red;
}