.pl-table-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;
}

.pl-table {
  width: 100%;
  border-collapse: collapse;
}

.pl-table th, .pl-table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.pl-table th, .financial-statements-table th{
  position: sticky;
  top: 0;
  background-color: var(--black);
  z-index: 1;
}

.report-name-row{
  position: sticky;
  top: 30px;
  z-index: 1;
  background-color: #FFF;
}

.pl-table th:first-child, .pl-table td:first-child {
  text-align: left;
}

.pl-table th:not(:first-child), .pl-table td:not(:first-child) {
  text-align: right;
}
