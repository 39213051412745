#content-container {
    display: flex;
}

#content-container .left-panel {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
    gap: 1rem;
    margin-top: 5rem;
    align-items: center;
}

#content-container .right-panel {
    flex: 1;
}

.workspace-icon {
    width: 5rem;
    height: 5rem;
}

.workspace-icon-active {
    border: 2px solid var(--gray);
    border-radius: 1rem;
    box-shadow: 1px 1px 10px var(--dark-gray);
}