.square {
    position: relative;
}

.square-content {
    height: 10rem;
    border: 2px solid #ccc;
    /* Add border */
    border-radius: 10px;
    /* Slightly rounded borders */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4fe2ff;
}

a {
    text-decoration: none;
}

.square-content:hover {
    background-color: #6ae5fe;
    transform: translateY(-2px);
    box-shadow: #bdbdbd59 -3px 3px;

}