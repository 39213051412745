#invoice-form-container {
  height: 100vh;
  background-color: #fff;
  z-index: 1;
  padding: 1rem 3rem;
  background-color: var(--beige);
}

#invoice-popup {
  height: 100vh;
  background-color: #fff;
  z-index: 1;
  padding: 1rem 3rem;
  background-color: var(--beige);
}

.invoice-info {
  width: 50%;
  margin-top: 3rem;
}

.invoice-info input,
.invoice-info select {
  width: 100%;
  background: transparent;
  border: 0;
  outline: none;
  padding-left: 1rem;
}

.invoice-info p {
  width: 12rem;
}

.invoice-info:first-child p {
  width: 6.5rem;
}

.invoice-info-row {
  margin-top: 4px;
}

.info-section {
  background-color: #d9ffd2;
  padding: 0.5rem;
  border-radius: 2rem;
  width: 30rem;
  text-align: center;
}

#invoice-table thead th {
  background-color: #3f3f3f !important;
  color: #fff;
  /* border-left: 1px solid #555;
    border-right: 1px solid #555; */
  padding: 5px 10px;
}

#invoice-table tbody tr {
  height: 2.8rem;
}

#invoice-table tbody tr {
  border-bottom: 0;
}

#invoice-table tr:last-of-type {
  border: 0;
}

#add-record {
  border: 0;
  border-radius: 1rem;
  padding: 5px;
  background: var(--grassy);
}

#invoice-table td {
  /* border-bottom: 1px solid #555; */
}

/* #invoice-table tr:hover :not(td:nth-of-type(8)) {
    background-color: var(--dar-gray);
} */

#invoice-table tr:hover td:nth-child(-n + 7) {
  background-color: var(--light-gray);
  /* Change this color to your desired background color */
}

#invoice-table tr:hover td:last-child {
  background-color: transparent;
}

#invoice-table tr td:nth-of-type(8) {
  background-color: transparent !important;
}

#invoice-table tr:hover td:nth-of-type(8) {
  background-color: transparent !important;
}

#invoice-table tr td:nth-of-type(8) {
  border: 0px;
}

.invoice-table-td {
  /* border-left: 1px solid #555;
    border-right: 1px solid #555; */
  padding: 0;
}

.invoice-table-td input {
  width: 100%;
  height: 2.7rem;
  border: 0;
  background: none;
  padding-left: 1rem;
}

.item-desc {
  width: 30%;
}

.qty {
  width: 5%;
}

.rate {
  width: 10%;
}

.taxable {
  width: 20%;
}

.tax-percentage {
  width: 10%;
}

.tax {
  width: 10%;
}

.amount {
  width: 20%;
}

.total-section {
  width: 18rem;
  float: right;
}

.total-section .flex-container p:first-of-type {
  width: 30%;
}

.total-section .flex-container p:last-of-type {
  width: 70%;
}

.add-discount p {
  width: 100% !important;
  margin: 0.5rem 0;
  text-align: right;
  color: var(--grassy);
}

.vat-total {
  padding: 3px;
  border: 1px dotted;
  text-align: right;
}

.tavat {
  border: none;
  background-color: var(--purple);
}

#add-client {
  position: absolute;
  right: -3rem;
}

.trash-cell {
  position: absolute;
  width: 0;
}

.trash-cell button {
  background-color: transparent;
  border: 0;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#submit-form {
  padding: 3.5px 6px;
  border-radius: 1rem;
  font-size: 1.2rem;
  position: fixed;
  bottom: 1rem;
  left: 7rem;
}

.client-list {
  min-width: 21.4rem;
  position: absolute;
  right: -22.4rem;
  top: 0;
  background-color: #fff;
  padding: 1rem;
  z-index: 9;
  display: none;
  height: 30rem;
  overflow-y: scroll;
  scrollbar-width: none;
}

.client-form-popup {
  /* display: block !important; */
  width: 30rem;
  right: -31rem;
}

.client-form-popup input {
  margin: 1rem 0;
  border-radius: 1rem;
  padding: 5px;
  border: 0.5px solid #eee !important;
}

.client-form-popup input[type="submit"]:hover {
  background-color: var(--grassy) !important;
}

ul {
  list-style-type: none;
}

.client-list li {
  padding: 5px;
  cursor: pointer;
  border-radius: 2rem;
  text-align: left;
}

.client-list li:hover {
  background-color: var(--light-gray);
}

.add-new-client {
  background-color: var(--light-green);
  padding: 5px;
  border-radius: 1rem;
  cursor: pointer;
}

.add-new-client:hover {
  background-color: var(--grassy);
}

#search-client {
  margin: 1rem 0;
}

.info-section p {
  padding-left: 1rem;
  text-align: left;
}

.client-form-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}

.create-client-button:hover {
  background-color: var(--grassy) !important;
}

.tax-rate {
  width: 95%;
  height: 2.8rem;
  background: transparent;
  border: 0;
}

#invoice-left-section {
  width: 85%;
}

.invoice-footer {
  position: fixed;
  bottom: 0;
  height: 17rem;
}

.bank-details-label-container p {
  width: 12rem;
}

.bank-details-container {
  width: 30rem;
}

.qr-code {
  width: 4rem;
  position: fixed;
  right: 7rem;
  bottom: 4rem;
}

.clear-both {
  clear: both;
}

.right-info {
  position: absolute;
  right: 4.5rem;
  top: 17rem;
}

.status {
  background-color: transparent;
  border: 0;
}

.invoice-logo {
  width: 5rem;
  height: 5rem;
}

.info-section select {
  min-height: unset;
}