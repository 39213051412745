AirtableData.css .table-containerTEST {
  width: 100%;
  overflow-x: auto;
  height: 100%;
}

tableTEST {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 12px;
  text-align: left;
}

table th,
table tdTEST {
  padding: 12px 0;
}

table thTEST {
  position: sticky;
  background-color: var(--black);
  color: #ffffff;
  text-transform: uppercase;
  top: 0;
  z-index: 1;
}

table trTEST {
  border-bottom: 1px solid #dddddd;
}

table tr:nth-of-type(even)TEST {
  background-color: #f3f3f3;
}

/* table tr:last-of-typeTEST {
    border-bottom: 2px solid #009879;
} */

table tr:hovertest {
  /* background-color: #f1f1f1; */
  background-color: #a9ff14;
}
