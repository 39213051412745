.logout-button:hover {
    background-color: #777777;
}

.logout-modal {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 400px;
    text-align: center;
    z-index: 1000;
    animation: slideInFromTop 0.5s ease-out;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 999;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.confirm-button, .cancel-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirm-button {
    background-color: var(--black);
    color: #fff;
}

.confirm-button:hover {
    background-color: #000;
}

.cancel-button {
    background-color: #ddd;
    color: #000;
}

.cancel-button:hover {
    background-color: #ccc;
}

/* #logout {
} */

.from-account {
    border: 0 !important;
    background: transparent;
    width: 100%;
}

.from-menu {
    position: fixed;
    bottom: 1rem;
    left: -30%;
    width: 12.7%;
    background: transparent;
    height: 4rem;
    transition: left 0.3s ease-in-out;
}

/* #logout:hover {
    background-color: var(--dark-gray);
} */

@keyframes slideInFromTop {
    0% {
        transform: translateX(-50%) translateY(-100%);
    }

    100% {
        transform: translateX(-50%) translateY(0);
    }
}