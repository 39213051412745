.gradient {
    height: 100vh;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(217, 217, 217, 1) 15%, rgba(163, 163, 163, 1) 100%);
}

.register-popup {
    background-color: #FFF;
    position: absolute;
    width: 45%;
    height: 60%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem 4rem;
    border-radius: 2rem;
}

.left-side {
    position: relative;
    width: 50%;
}

.form-row {
    width: 100%;
    background-color: red;
}

.form-row input {
    width: 100%;
}

.register-container {
    background-color: #FFF !important;
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
}

.user-box input {
    padding-left: 1rem;
}

.login-box .user-box input {
    padding-left: 1rem !important;
}