#page-color {
  background-color: #FAF9F9;
}

#navbar-side {
  position: fixed;
  left: 0;
  width: 3%;
  height: 100%;
  background-color: var(--light-gray);
  transition: 0.5s;
  /* overflow-x: hidden; */
}

#main-side {
  position: relative;
  left: 3%;
  padding: 1rem;
  padding-left: 0;
  height: 100vh;
  width: 97%;
  /* 100% - 3% */
  background-color: #f9f9f9;
}

#navbar-side-right {
  position: fixed;
  z-index: 1;
  top: 0;
  right: -16.7%;
  /* Hidden by default */
  height: 100%;
  width: 3%;
  background-color: var(--light-gray);
  overflow-x: hidden;
  transition: right 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#navbar-side-right.expanded {
  right: 0;
  /* Expand on hover */
}

#menu-icon-right {
  margin-top: 20px;
  cursor: pointer;
}

.vertical-flex-container-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nav-item-right {
  margin: 10px 0;
  cursor: pointer;
  transition: transform 0.2s;
}

.nav-item-right:hover {
  transform: scale(1.1);
}

.menu-width-right {
  position: absolute;
  bottom: 20px;
}

.bottom-position-right {
  position: absolute;
  bottom: 10px;
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 30%;
  /* Adjust width as needed */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  /* Full width */
  max-width: none;
  /* No max-width */
  text-align: center;
  position: relative;
  height: 100%;
  /* Full height */
  overflow-y: auto;
  /* Scroll if content is too long */
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
}