#invoices-table {
  position: relative;
  top: 0px;
  margin-top: 0;
}

#invoices-table tr:hover {
  background-color: var(--light-gray);
}

#invoice-table td.customer-circle-td {
  height: 42.5px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-pdf {
  width: 15px;
  text-align: center;
}

.customer-circle {
  background-color: var(--grassy);
  text-align: center;
  width: fit-content;
  padding: 8px;
  border-radius: 50%;
}

.half-cell {
  min-width: 2rem;
}

.customer-cell {
  width: 20%;
  min-width: 20%;
  max-width: 20%;
}

.normal-cell {
  width: 9%;
  min-width: 9%;
  max-width: 9%;
}

.expand-td {
  padding: 0;
}

.expand-td img {
  height: 2.1rem;
  display: block;
  margin: 0 auto;
}

.customer-circle-td {
  /* width: 3rem; */
  display: flex !important;
  justify-content: center;
}

td {
  padding: 0 !important;
}