.add_workspace:hover {
    background-color: #ddd;
    color: #000;
    border: 1px solid #eee;
    cursor: pointer;
}

#account-popup {
    background: white;
    min-width: 20rem;
    position: absolute;
    bottom: -4rem;
    right: 0;
    padding: .5rem;
}

#account-popup ul {
    list-style-type: none;
}

#account-popup ul li {
    padding: .5rem;
    cursor: pointer;
}

#account-popup ul li:hover {
    background-color: var(--light-gray);
}

.icon-container:first-child .collaborator-circle {
    background-color: var(--dark-gray);
}

.icon-container:nth-of-type(2) .collaborator-circle {
    position: relative;
    left: -1rem;
}

.collaborator_users_section .collaborator-circle:nth-of-type(3) {
    position: relative;
    left: -1rem;
    /* background-color: red; */
}

.collaborator_users_section a {
    position: relative;
    left: -1rem;
    /* background-color: red; */
}

#landing-menu-logo {
    position: fixed;
    top: 1rem;
}

#workspace-ul {
    width: 19%;
    position: fixed;
    bottom: 1rem;
    z-index: 2;
}

#option-container {
    width: 20%;
    position: fixed;
    top: -11rem;
}