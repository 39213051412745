a {
    text-decoration: none !important;
}

#menu-side {
    width: 25%;
    height: 100vh;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    flex: 2.5 1 !important;
}

#companies-side {
    background-color: var(--light-gray);
    height: 100%;
    padding: 1rem 3rem;
    min-height: 100vh;
}

#menu-side .logo {
    width: 4rem;
    height: 4rem;
}

#companies {
    padding: 0 2rem;
}

#workspace-info {
    margin-bottom: 1rem;
}

.options {
    padding: 0 2rem;
    margin-top: 15%;
}

.option-list {
    list-style-type: none;
}

.option-list-item {
    background-color: #FFF;
    padding: 2rem 0;
}

.option-list-item:last-child() {
    margin-bottom: 1rem;

}

.option-list-item:hover {
    background-color: var(--light-gray);
    cursor: pointer;
}

.option-list-item p {
    font-size: 1.8rem;
    display: inline-block;
}

.selected-option {
    background-color: var(--light-gray);
}

.companies-container {
    border: 1px solid var(--light-gray);
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s ease-out;
    display: none;
}

.current-workspace li p {
    width: 80%;
}

.user-circle {
    width: 6rem;
    height: 6rem;
    background: var(--gray);
    border-radius: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-circle p {
    width: 50%;
    text-align: center;
}

.companies-row {
    display: flex;

    .left-column {

        width: 10rem;
    }

    .right-column {
        margin-left: 2%;
        width: 89%;
    }
}

.company-image {
    width: 10rem;
    height: 10rem;
}

.company-image:hover {
    border: 2px solid #FFF;
    border-radius: 22px;
}

.company-description {
    width: 100%;
    height: 10rem;
    background-color: #FFF;
}

.company-lower-part {
    height: 2.5rem;
    padding: 4px 1rem 4px 1rem;
    text-align: center;
}

.collaborator-container {
    margin-top: 2rem;
    width: 10rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: .5rem;
}

.collaborator-circle {
    background-color: var(--gray);
    width: 3rem;
    height: 3rem;
    margin-left: .25rem;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}