.bank-cell {
    background-color: #FFF;
    width: 25rem;
    height: 9rem;
    border-radius: 1rem;
    padding-left: 7px;
    padding-right: 3px;
}

.bank-cell img {
    width: 8rem;
}

.banks-container {
    margin-left: 1rem;
    width: 25rem;
}

#add-bank {
    margin-top: 1rem;
    width: 25rem;
    height: 3rem;
    border-radius: 1rem;
    background-color: var(--grassy);
    border: 0;

}

.banks-container .bank-cell:not(:first-child) {
    margin-top: 1rem;
}

.bank-account-container {
    align-items: flex-start !important;
    width: 29rem;
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.input-shape {
    border-radius: 1rem;
    height: 3rem;
    border: 0;
    background-color: #FFF;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;

}

.input-shape input {
    border: 0;
    width: 100%;
}

.main-form-container {
    position: relative;
    left: 3rem;
}

.form-container {
    width: 60%;
}

.bank-account-row {
    gap: .5rem;
    margin: 0rem;
}

.bank-account-row:not(:first-child) {
    margin-top: .5rem;
}

.selected-bank {
    border: 1px solid var(--grassy);
    box-shadow: 1px 1px 10px var(--grassy);
}

#bank-account-form {
    position: fixed;
    width: 100rem;
}