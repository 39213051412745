.legal-info-column {
    width: calc(50% - 2rem);
    row-gap: 1rem;
}

.legal-info-column .input-shape {
    border-radius: 1rem;
    height: 3rem;
    border: 0;
    background-color: #FFF;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.legal-info-column .date-shape {
    border-radius: 1rem;
    height: 3rem;
    border: 0;
    background-color: #FFF;
    padding-right: 1rem;
}

.legal-info-column input {
    padding-left: 1rem;
}

.input-shape p {
    padding-left: 1rem;
    padding-top: 5px;
    display: block;
    width: fit-content;
    float: left;
}

.shape {
    flex: 1;
}