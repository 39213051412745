#invoice-right-section #invoice-popup {
  height: 100vh;
  background-color: #fff !important;
  z-index: 1;
  padding: 1rem 3rem;
}

#invoice-right-section #invoice-info {
  width: 65%;
  margin-top: 3rem;
}

#invoice-info input,
#invoice-right-section #invoice-info select {
  width: 100%;
  background: transparent;
  border: 0;
  outline: none;
  padding-left: 1rem;
}

#invoice-right-section #invoice-info p {
  /* width: 8rem; */
}

#invoice-right-section .invoice-info-row {
  margin-top: 4px;
}

#invoice-right-section .info-section {
  background-color: #d9ffd2;
  padding: 0.5rem;
  border-radius: 2rem;
  width: 30rem;
  text-align: center;
}

#invoice-right-section #invoice-table thead th {
  background-color: #3f3f3f !important;
  color: #fff;
  /* border-left: 1px solid #555;
    border-right: 1px solid #555; */
  padding: 5px 10px;
}

#invoice-right-section #invoice-table tbody tr {
  height: 2.8rem;
}

#invoice-right-section #invoice-table tbody tr {
  border-bottom: 0;
}

#invoice-right-section #invoice-table tr:last-of-type {
  border: 0;
}

#invoice-right-section #add-record {
  border: 0;
  border-radius: 1rem;
  padding: 5px;
  background: var(--grassy);
}

#invoice-right-section #invoice-table td {
  /* border-bottom: 1px solid #555; */
}

#invoice-right-section #invoice-table tr:hover :not(td:nth-of-type(8)) {
  background-color: var(--light-gray);
}

#invoice-right-section #invoice-table tr td:nth-of-type(8) {
  background-color: #fff;
}

#invoice-right-section #invoice-table tr:hover td:nth-of-type(8) {
  background-color: #fff;
}

#invoice-right-section #invoice-table tr td:nth-of-type(8) {
  border: 0px;
}

#invoice-right-section .invoice-table-td {
  /* border-left: 1px solid #555;
    border-right: 1px solid #555; */
  padding: 0;
}

#invoice-right-section .invoice-table-td input {
  width: 100%;
  height: 2.7rem;
  border: 0;
  background: none;
}

#invoice-right-section .item-desc {
  width: 30%;
}

#invoice-right-section .qty {
  width: 5%;
}

#invoice-right-section .rate {
  width: 10%;
}

#invoice-right-section .taxable {
  width: 20%;
}

#invoice-right-section .tax-percentage {
  width: 10%;
}

#invoice-right-section .tax {
  width: 10%;
}

#invoice-right-section .amount {
  width: 20%;
}

#invoice-right-section .total-section {
  width: 32rem;
  float: right;
}

#invoice-right-section .total-section .flex-container p:first-of-type {
  width: 50%;
}

#invoice-right-section .total-section .flex-container p:last-of-type {
  width: 50%;
}

#invoice-right-section .add-discount p {
  width: 100% !important;
  margin: 0.5rem 0;
  text-align: right;
  color: var(--grassy);
}

#invoice-right-section .vat-total {
  padding: 3px;
  border: 1px dotted;
  text-align: right;
}

#invoice-right-section .tavat {
  border: none;
  background-color: var(--grassy);
  border: 1px solid var(--grassy);
}

#invoice-right-section #add-client {
  position: absolute;
  right: -3rem;
}

#invoice-right-section .trash-cell {
  position: absolute;
  width: 0;
}

#invoice-right-section .trash-cell button {
  background-color: transparent;
  border: 0;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#invoice-right-section #submit-form {
  position: fixed;
  bottom: 1rem;
  left: 3rem;
}

#company-info {
  text-align: right;
}

.total-vat {
  height: 33.5px;
}

#popup-note {
  height: 1.8rem;
}

@media (max-width: 1440px) {
  .invoice-popup-right {
    font-size: 10px !important;
  }
}

.total-after-vat {
  border-radius: 1rem;
}

#main-side {
  padding: 0;
}

.invoice-popup-header {
  min-height: 71.99px;
}
