.box {
  padding: 1rem;
}

.border-green {
  border: 1px solid #38ff13;
}

.black-border {
  border: 1px solid rgb(0 0 0 / 26%) !important;
}

.dotted-box {
  border: 1px dotted;
  --bs-border-opacity: 1;
  border-color: rgb(2 2 2 / 21%) !important;
}

.box-size-m {
  width: 25rem;
}

.size_1_3 {
  width: 31%;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.rounded-radius {
  border-radius: 1rem !important;
}

.margin-10-percent {
  margin-right: 10%;
}

#block-1 .left-side {
  padding: 1rem;
}

#block-1 .right-side {
  padding: 1rem;
}

#block-1 .upper-part {
  background-color: #eee;
}

.small-button {
  width: 15rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

/* .block {
  margin: 1rem 0;
} */

.padding-1-lr {
  padding-left: 1rem;
  padding-right: 1rem;
}

#block-3 .icon {
  display: block;
  height: 4rem;
  width: 4rem;
  background-color: #eee;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.margin-5-p {
  margin-left: 5px;
  margin-top: 5px;
}

.pr-5-p {
  padding-right: 5px;
}

#wavingHand {
  height: 3rem;
  margin-right: 1rem;
}

#header-2 .empty-box {
  max-width: 21rem;
}

.standard-icon {
  height: 1.5rem;
}

.rajhi-icon {
  height: 3rem;
}

.hsbc-icon {
  height: 2rem;
}

.black-icon {
  height: 3rem;
}

.bg-light-green {
  background-color: #d9ffd2;
}

.incoming-price {
  /* margin-left: -2rem;
    margin-top: 1.5rem; */
}

#menu-icon {
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 3rem;
  cursor: pointer;
}

.nav-item {
  width: 3rem;
  margin-bottom: 1rem;
}

.bg-positive {
  background-color: var(--light-green);
}

.bg-negative {
  background-color: var(--pink);
}

.invoice {
  position: relative;
}

.invoice-option-container {
  position: absolute;
  left: 4.8rem;
  z-index: 9;
  top: 1.8rem;
  display: none;
}

.invoice-option {
  background-color: var(--light-gray);
  padding: 0.5rem;
  width: 14rem;
  height: 3rem;
  border-radius: 2rem;
  border: 1px solid #000;
  text-align: center;
}
