/* src/components/Login.css */
.login-page {
    background: #FFF;
    background: linear-gradient(90deg, #FFFFFF, #A9FF14 100%, #A9FF14 100%);
    width: 100vw;
    height: 100%;
}

.left-side {
    position: relative;
    width: 50%;
    /* height: 100vh; */
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-box {
    background-color: #FFF;
    position: absolute;
    width: 50%;
    height: 60%;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -50%);
    padding: 2rem 4rem;
    border-radius: 2rem;

    /* position: relative; */
    /* width: 400px; */
    padding: 40px;
    /* box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5); */
    border-radius: 10px;
    /* text-align: center; */
}

.login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #000;
}

.login-box .user-box {
    position: relative;
}

.login-box .user-box:nth-of-type(1) input {
    /* margin-bottom: 3rem; */
}

.login-box form {
    /* 18% is the height of .register-section */
    height: calc(100% - 8rem);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.logo {
    width: 76%;
}

.login-box .user-box input {
    width: 100%;
    padding: 6px 0;
    font-size: 16px;
    color: #000;
    /* margin-bottom: 30px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    /* border-bottom: 1px solid #fff; */
    outline: none;
    background: transparent;
    -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.8) inset;
    -webkit-text-fill-color: #000;
}


.login-button {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #000;
    font-size: 16px;
    text-decoration: none;
    overflow: hidden;
    transition: 0.4s;
    margin-top: 40px;
    width: 100%;
    background-color: var(--purple);
    border: 0;
    border-radius: 5px;
}

.login-button:hover {
    /* background: var(--dark-gray); */
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #d3d3d3, 0 0 25px #ffffff, 0 0 50px #ffffff, 0 0 100px #ffffff;
}

.login-button span {
    position: absolute;
    display: block;
}

.login-button span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
    0% {
        left: -100%;
    }

    50%, 100% {
        left: 100%;
    }
}

.login-button span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    animation: btn-anim2 1s linear infinite;
    animation-delay: 0.25s;
}

@keyframes btn-anim2 {
    0% {
        top: -100%;
    }

    50%, 100% {
        top: 100%;
    }
}

.login-button span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    animation: btn-anim3 1s linear infinite;
    animation-delay: 0.5s;
}

@keyframes btn-anim3 {
    0% {
        right: -100%;
    }

    50%, 100% {
        right: 100%;
    }
}

.login-button span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    animation: btn-anim4 1s linear infinite;
    animation-delay: 0.75s;
}

@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }

    50%, 100% {
        bottom: 100%;
    }
}

.error {
    color: red;
    margin-top: 10px;
}

/* Autofill styles for different browsers */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.8) inset;
    -webkit-text-fill-color: #fff;
}

input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
input:-webkit-autofill:active {
    /* -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.8) inset; */
    /* -webkit-text-fill-color: #fff; */
}

.forget-password-container p {
    color: var(--gray);
}

a {
    color: #000 !important;
}

a:hover {
    color: var(--dark-gray) !important;
}

.forget-password {
    width: 100%;
    text-align: right;
}

.register-section {
    background-color: #F9F6F6;
    position: absolute;
    bottom: 0;
    height: 18%;
    width: 100%;
    left: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    text-align: center;
}

.register-section p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.google-button {
    padding: 5px;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 1rem;
}

.accept-terms input {
    width: 1.8rem;
    height: 1.8rem;
}

.term-color {
    color: var(--purple) !important;
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .login-box {
        height: 62%;
    }

    .registration-box {
        height: 79% !important;
    }
}