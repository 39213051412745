a {
    text-decoration: none !important;
}

#collaborator-side {
    background-color: var(--light-gray);
    min-height: 100vh;
    height: 100%;
    padding: 1rem 3rem;
}

#title h1 {
    font-size: 5rem;
    font-weight: bold;
}

#invite-collaborator {
    margin-top: 3rem;
}

#collaborator-owner {
    margin-top: 4rem;
}

#invite-collaborator-form {
    position: relative;
    margin-top: 1rem;
    /* background-color: #FFF; */
    height: 5rem;
    width: 100%;
    padding: .5rem;
    /* font-size: 2rem; */
}

#invite-collaborator-form input {
    font-size: 2rem;
    width: 98%;
    height: 4rem;
    border: 0;
}

#workspace-collaborator {
    margin-top: 2rem;
}

.small-title {
    font-size: 3rem;
}

.invite-submit {
    background-color: var(--light-orange) !important;
    width: 113px;
    margin-left: 1%;
    height: 4rem;
    border: 0;
}

.small-circle {
    width: 4rem !important;
    height: 4rem !important;
}

.white-box {
    padding: 1rem;
}

.user-info {
    margin-left: 1rem;
}

.user-role {
    background: var(--gray);
    width: 14rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-role-drop-down {
    padding: 0 3px;
    min-width: 14rem;
}

.user-role-drop-down select {
    text-align: center;
    min-width: 13rem;
    border: 0;
    background: transparent;
    outline: none;
}

.white-box {
    margin-top: 2rem;
}

.edit-viewing {
    background-color: #FFF;
    border: 1px solid var(--gray);
    width: 168px;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-row:not(:first-child) {
    margin-top: 1rem;
}

.fit-content {
    width: fit-content !important;
}

.role_image {
    width: 3rem;
}

.role-shape {
    min-width: 168px;
    font-size: 11px;
}