@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .custom-cell {
    @apply px-2 py-2 text-lg text-gray-700 border-b border-gray-200 whitespace-nowrap;
  }

  .custom-header-cell {
    @apply px-4 py-3 font-medium text-gray-600 tracking-wider whitespace-nowrap min-h-[36px] h-[36px] uppercase;
  }
}
