/* @font-face {
    src: url(/fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf);
    font-family: ibm;
} */

html {
  font-size: 62.5%;
  overflow-x: clip;
  font-family: "ibm", "Comfortaa", serif !important;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

/*
 ! this should be deleted this is the most bad way to overvlow
 ! here the overflow is for the HTML TAG!!! 
 ! instead the overflow must be on the scroll-container (found in App.tsx)
 * Hide scrollbar for Chrome, Safari and Opera 
 */
html::-webkit-scrollbar {
  display: none;
}

.regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.extra-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}

p,
h1,
h2,
h3,
ul,
input,
span {
  padding: 0;
  margin: 0;
  color: var(--black);
}

.overnight {
  display: none !important;
}

.show_element {
  display: block !important;
}

.bold {
  font-weight: 700;
}

.sticky_cell {
  position: sticky;
  top: 9.7rem;
  background-color: white;
  z-index: 2;
}

.juno_bg {
  background-color: var(--juno);
}

.airbnb_bg {
  background-color: var(--airbnb);
}

.light-blue_bg {
  background-color: var(--light-blue);
}

.dark-gray_font {
  color: var(--dark-gray);
}

.border_color {
  border-color: #d8d8d8;
}

.today_cell {
  background-color: #fef5e5 !important;
  z-index: -1;
}

.week_end {
  background-color: #f9f9f9;
}

#current_date {
  position: fixed;
  z-index: 9999;
  top: 5rem;
  left: 5rem;
}

.loading_icon {
  position: fixed;
  top: 50%;
  left: calc(65% - (11.2rem));
  transform: translate(-50%, -50%);
  width: 36rem;
  z-index: 99;
}

.white_font {
  color: #fff;
}

.text-gray {
  color: var(--gray);
}

.dialogue {
  display: none;
  background-color: white;
  text-align: left;
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px solid var(--black);
  margin-left: 10px;
  margin-top: -5px;
  font-weight: normal;
  font-size: 12px;
  color: var(--black);

  position: absolute;
  z-index: 99999;
}

.has-dialogue {
  position: relative;
  overflow: visible !important;
}

#menu_dialogue {
  font-size: 11px;
  background: var(--propke_color);
  height: calc(100vh - 3rem);
  width: 506px;
  top: 3rem;
  left: -5px;
  right: unset;
  position: absolute;
  margin-left: 0;
}

.desktop_only {
  display: block;
}

.mobile_only {
  display: none;
}

@media screen and (max-width: 768px) {
  .desktop_only {
    display: none;
  }

  .mobile_only {
    display: block;
  }
}

.listing-tabs {
  width: 100%;
  height: auto;
  /* margin: 30px auto; */
}

/* tab list item */
.listing-tabs .listing-tabs-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
  /* display:table-row; */
}

.listing-tabs .listing-tabs-list li {
  float: left;
  margin: 0px;
  margin-right: 2px;
  padding: 4px 20px;
  text-align: center;
  /* background-color: #2bb4b0; */
  background-color: #f08587;
  height: 27px;
}

.sub_tabs .listing-tabs-list li {
  float: left;
  margin: 0px;
  margin-right: 2px;
  padding: 4px 20px;
  text-align: center;
  background-color: #f3aaac;
  height: 27px;
}

.listing-tabs .listing-tabs-list li:hover {
  cursor: pointer;
}

.listing-tabs .listing-tabs-list li a {
  text-decoration: none;
  color: white;
  font-size: 12px;
  padding: 0;
}

.listing-tab td {
  font-size: 12px;
}

.listing-tab tr {
  height: 30px;
}

.listing-tab td input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
}

.inner-listing-tab {
  margin-top: 15px;
}

/* Tab content section */
.listing-tabs .listing-tab {
  display: none;
  width: 100%;
  height: auto;
  border-radius: 3px;
  padding: 20px 15px;
  background-color: white;
  color: darkslategray;
  clear: both;
  margin-top: 0px;
}

.listing-tabs-list li {
  border-radius: 0.5rem;
}

.scroll-container {
  position: relative;
  top: 29px;
}

.side_popup {
  position: fixed;
  top: 3rem;
  right: 0;
  width: 30%;
  height: calc(100vh - 3.1rem);
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1;
  border: 1px solid #e9e9e9;
  border-top-left-radius: 9px;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  padding: 10px;
  transform: translateX(100%);
  transition: transform 1s ease;
}

.side_popup.visible {
  transform: translateX(0);
}

.side_popup.hidden {
  transform: translateX(100%);
}

#plus_button {
  position: fixed;
  bottom: 20px;
  background: var(--propke_color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  right: 25px;
  border: 0;
  cursor: pointer;
  outline: transparent;
  display: block;
}

#plus_button svg {
  font-size: 20px;
  color: #fff;
  position: relative;
  left: 0.25px;
  top: 1px;
}

.margin-5rem {
  margin-top: 5rem;
}

.upper-case {
  text-transform: uppercase;
}

.color-black {
  color: var(--black);
}

.orang-text {
  color: var(--light-orange);
}

.bg-light-gray {
  background-color: var(--light-gray);
}

.bg-gray {
  background-color: var(--gray);
}

.bg-dark-gray {
  background-color: var(--dark-gray);
}

.bg-white {
  background-color: #fff;
}

.bg-grassy {
  background-color: var(--grassy);
}

.bg-pink {
  background-color: #ffb1b8;
}

.bg-purple {
  background-color: #e521e0;
}

.bg-green {
  background-color: #38ff13;
}

.black-bg {
  background-color: var(--black);
}

.flex-vertical-direction {
  flex-direction: column !important;
}

.vertical-flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  justify-content: center !important;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-small-gap {
  gap: 1rem;
}

.flex-2-3 {
  flex: 2;
}

.flex-1-3 {
  flex: 1;
}

.flex-1-4 {
  flex: 1;
}

.flex-3-4 {
  flex: 3;
}

.flex-10 {
  flex: 10;
}

.flex-2-4 {
  flex: 2;
}

.flex-space-around {
  justify-content: space-around !important;
}

.flex-space-between {
  justify-content: space-between !important;
}

.flex-start {
  justify-content: flex-start !important;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.pd-1 {
  padding: 1rem;
}

.p-1-p {
  padding: 1px;
}

.p-l-1 {
  padding-left: 1rem !important;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pt-5 {
  padding-top: 5rem;
}

.p-t-1 {
  padding-top: 1rem !important;
}

.p-t-2 {
  padding-top: 2rem !important;
}

.p-t-3 {
  padding-top: 3rem !important;
}

.p-v-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-h-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.p-b-1 {
  padding-bottom: 1rem !important;
}

.p-b-2 {
  padding-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 5rem;
}

.margin-1 {
  margin: 1rem;
}

.m-l-25-neg {
  margin-left: -0.25rem;
}

.m-l-2 {
  margin-left: 2rem;
}

.m-l-3 {
  margin-left: 3rem;
}

.m-r-1 {
  margin-right: 1rem;
}

.m-b-1 {
  margin-bottom: 1rem;
}

.m-t-1 {
  margin-top: 1rem;
}

.m-t-2 {
  margin-top: 2rem !important;
}

.m-t-3 {
  margin-top: 3rem !important;
}

.m-t-5 {
  margin-top: 5rem !important;
}

.m-l-1 {
  margin-left: 1rem;
}

.p-v-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.rounded-radius {
  border-radius: 1rem !important;
}

.bold-text {
  font-weight: bold;
}

.w-100 {
  width: 100%;
}

.half-width {
  width: 50%;
}

.margin-y-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.align-center {
  text-align: center !important;
}

.align-stretch {
  align-items: stretch;
}

.text-white {
  color: white;
}

.upper-case {
  text-transform: uppercase;
}

.padding-half {
  padding: 0.5rem;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.h-100 {
  height: 100%;
}

.m-b-5 {
  margin-bottom: 5rem;
}

.inactive {
  opacity: 0.5;
  pointer-events: none;
}

.main-side {
  background-color: var(--light-gray);
  height: 100%;
  min-height: 100vh;
  padding: 1rem 3rem;
}

.inactive-input {
  pointer-events: none;
}

.menu-main-content {
  height: calc(100% - 4rem);
}

.bottom-position {
  position: fixed;
  bottom: 1rem;
}

#menu {
  background-color: var(--light-gray);
  position: fixed;
  width: 12.7%;
  top: 0;
  height: 100%;
  /* padding: 1rem; */
  z-index: 4;
  left: -30%;
  transition: left 0.3s ease-in-out;
}

#menu ul {
  list-style: none;
  padding: 0;
  margin-top: 5rem;
}

#menu ul li {
  padding: 1rem 1rem;
  font-size: 1.5rem;
  color: #000;
}

#menu ul li:hover {
  background-color: var(--dark-gray);
  color: #333;
  cursor: pointer;
}

#menu #close {
  float: right;
  font-size: 2rem;
  padding: 1rem 2rem;
  cursor: pointer;
}

#user-section {
  min-height: 84px;
  position: relative;
}

.clickable {
  /* don't allow text select */
  user-select: none;
  transition: all 0.3s ease;
}

.clickable:active {
  transform: scale(0.96);
}

.pointer {
  cursor: pointer !important;
}

.default-cursor {
  cursor: default !important;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.hover-label {
  display: none;
  position: absolute;
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
}

.icon-container:hover .hover-label {
  display: block;
}

.image-circle {
  border-radius: 50%;
}

.member-image {
  width: 4rem;
  height: 4rem;
}

.hover-element:hover {
  border: 2px solid #aaaaaa55;
}

.account-drop-down-item {
  display: block;
  text-align: center;
  color: #000;
}

.landing-menu-logo {
  width: 5rem;
  height: 5rem;
}

.image-w-3 {
  width: 3rem;
}

.transparent-border {
  border: 1px solid transparent !important;
}

.error a {
  color: red !important;
}

.position-relative {
  position: relative;
}

.float-right {
  float: right;
}

.grassy-button {
  background: transparent;
  border: 1px solid var(--grassy);
  padding: 1rem 2rem;
  border-radius: 1rem;
}

/* input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    box-shadow: 0 0 0 1000px transparent inset;
    color: #000;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
} */
.p-r-2 {
  padding-right: 2rem;
}

.sejel-button {
  width: 10rem;
  height: 4rem;
  border-radius: 1rem;
}

.w-24px {
  width: 24px;
}

.flex-start {
  align-items: flex-start;
}

.opacity-0 {
  opacity: 0;
}

.flex-align-center {
  align-items: center;
}

a.create-button {
  background: var(--light-gray);
  padding: 1rem;
  position: fixed;
  right: 3rem;
  bottom: 2rem;
  border-radius: 1rem;
}

div.create-button {
  background: var(--light-gray);
  padding: 1rem;
  position: fixed;
  right: 3rem;
  bottom: 2rem;
  border: 0;
  border-radius: 1rem;
  min-width: 10rem;
  text-align: center;
}

.unselectable {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Standard syntax */
}

input.create-button {
  background: var(--light-gray);
  padding: 1rem;
  border: 0;
  border-radius: 1rem;
  min-width: 10rem;
  position: relative;
  bottom: 4rem;
  float: right;
  right: 3rem;
}

.extra-popup {
  background: #fff;
  position: fixed;
  width: 50%;
  height: 100vh;
  right: -100%;
  transition: right 0.5s ease-out;
  top: 0;
  z-index: 1;
  border: 1px solid #eee;
  border-radius: 1rem;
  padding: 1rem 3rem 1rem 1rem;
  box-shadow: var(--dark-gray) 0 0 10px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.extra-popup.show {
  right: 0;
}

.required {
  color: red;
}

.workspaces-container {
  position: absolute;
  bottom: 10rem;
  background: white;
  width: 32rem;
  border: 1px solid var(--gray);
  border-radius: 1rem;
  z-index: 1;
  right: 0rem;
  /* transition: slidIn 1s ease-out; */
  /* Use transform for smooth transitions */
  transform: translateX(-60rem);
  /* Default position */
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(0rem);
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(32rem);
  }
}

.workspaces-container.show {
  animation: slideIn 0.2s ease-out forwards;
  /* Move the container to the right when .show is added */
}

.expand-icon {
  width: 1.7rem;
  height: 1.7rem;
  color: var(--black);
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.border-r-1 {
  border-right-width: 1px;
}

.has-dialogue {
  position: relative;
  overflow: visible !important;
}

.dialogue {
  display: none;
  background-color: white;
  text-align: left;
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px solid black;
  margin-left: 10px;
  margin-top: -5px;
  font-weight: normal;
  font-size: 12px;
  color: black;
  position: absolute;
  z-index: 99999;
}

.navigate-button {
  background-color: #0e0e0e;
  width: clamp(10rem, 10rem, 10rem);
  color: #fff;
  border: 1px solid #e0e0e0;
  margin: 0 0.5rem 2rem 0.5rem;
  border-radius: 15px;
  padding: 1rem;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.create-container {
  position: absolute;
  right: 2rem;
  bottom: 7rem;
  padding: 1rem;
}

.create-pile {
  display: block;
  background: var(--grassy);
  border-radius: 1.5rem;
  padding: 5px;
  text-align: center;
  margin-bottom: 5px;
}

.create-pile:last-child {
  margin-bottom: 0;
}

.financial-text {
  text-align: right;
}

.error-container {
  position: fixed;
  background: #fff;
  width: 25%;
  left: 50%;
  transform: translateX(-50%);
  top: 1rem;
  border-radius: 1.5rem;
  padding: 1rem 1rem 1rem 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.error-container-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  font-size: 2rem;
}

.error-container-points {
  list-style-type: disc;
  padding: 2.5rem 2rem;
}

.error-container-points span {
  color: #f00;
}

.dropdown-ul {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 25rem;
  background-color: white;
  scrollbar-width: none;
  border: 1px solid #cccccc5e;
}


/* Container for the icon */
.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .tooltip-container::after {
  content: attr(data-title);
  position: absolute;
  bottom: 0;
  left: 100%;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
} */

.tooltip-container:hover::after {
  opacity: 1;
  visibility: visible;
}

.navbar-icon {
  font-size: 20px !important;
}

.navbar-icon-active {
  /* filter: drop-shadow(0 0 .35px currentColor) drop-shadow(0 0 .35px currentColor); */
  /* Double shadow for more thickness */
  /* stroke: currentColor; */
  /* Add stroke to make the lines thicker */
  /* stroke-width: 0.5px; */
  /* Adjust stroke width for more solidity */
  /* transform: scale(1.05); */
  /* Slightly scale up the icon */

}

.active-indicator {
  background: black;
  height: 4.5rem;
  width: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}

.navbar-icon-title {
  position: absolute;
  left: 100%;
  bottom: 9px;
  background: black;
  color: white;
  padding: 3px 15px;
  border-radius: 8px;
  width: 18rem;
}

/* .table-component {
  padding: 0 !important;
} */

th {
  text-transform: uppercase;
}